/* specific slider styles */

/* facet range sliders */
.facet-range-slider .mat-mdc-slider {
  width: 95%;
}

.facet-range-slider .mdc-slider__track--inactive {
  @apply bg-true-gray-400;
}

.facet-range-slider .mdc-slider__track--active_fill {
  @apply border-tertiary-200;
}

.facet-range-slider .mdc-slider__thumb-knob {
  @apply bg-tertiary-200 border-tertiary-200;
}

.facet-range-slider .mdc-slider__thumb:hover .mdc-slider__thumb-knob {
  @apply bg-tertiary-200 border-tertiary-200;
}

.facet-range-slider .mdc-slider__thumb--focused .mdc-slider__thumb-knob {
  @apply bg-tertiary-200 border-tertiary-200;
}

.facet-range-slider .mdc-slider__value-indicator-text {
  @apply whitespace-nowrap;
}