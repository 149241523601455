/* specific dialog styles */

/* Esignup dialog */
.esignup-dialog-container .mat-dialog-container {
  @apply p-0 overflow-hidden;
}

/* ship gallery dialog */
.ship-gallery .mat-mdc-dialog-surface.mdc-dialog__surface {
  @apply bg-[#EFEFEF] rounded-lg;
}

/* admin edit dialog */
.admin-dialog .mat-mdc-dialog-surface.mdc-dialog__surface {
  @apply p-4 bg-[#EFEFEF] rounded-lg shadow-none;
}

/* quote request dialog */
.quote-request .mat-mdc-dialog-surface.mdc-dialog__surface {
  @apply p-0 bg-primary-100 rounded-lg;
}

/* promotion contact request dialog */
.contact-request .mat-mdc-dialog-surface.mdc-dialog__surface {
  @apply p-0 bg-primary-100 rounded-lg;
}