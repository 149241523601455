/* You can add global styles to this file, and also import other style files */
@import '../node_modules/tailwindcss/base.css';
@import '../node_modules/tailwindcss/components.css';
@import '../node_modules/tailwindcss/utilities.css';
@import '../node_modules/swiper/swiper-bundle.min.css';
@import '../node_modules/quill/dist/quill.snow.css';
@import './assets/css/base.css';
@import 'easymde/dist/easymde.min.css';
@import './assets/css/quill.css';
@import './assets/css/mat-dialog.css';
@import './assets/css/custom-easy-mde-styles.css';
@import './assets/css/form.css';
@import './assets/css/mat-form.css';
@import './assets/css/mat-slider.css';



html,
body {
    height: 100%;
}

body {
    margin: 0;
}
