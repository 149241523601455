/* GLOBAL STYLES */
html {
  scroll-padding-top: 126px;
}

body {
  @apply font-normal text-base text-black leading-normal;
}

p:not(.opt-out) {
  @apply mb-5;
}

.width-container {
  @apply w-full max-w-screen-xl mx-auto;
}

@media (min-width: 1024px) {
  html {
    scroll-padding-top: 162px;
  }
}

a:not(.opt-out) {
  @apply text-sm font-bold leading-[150%] text-tertiary-100;
}

.btn, a.btn {
  @apply py-3 px-4 text-sm font-bold leading-[120%] text-white tracking-wide bg-tertiary-100 border-2 rounded-sm border-tertiary-200 uppercase;
  will-change: filter;
  transition: filter .2s, background-color 0.2s;
}

.btn:hover, a.btn:hover {
  @apply bg-tertiary-300 border-tertiary-100;
  filter: drop-shadow(0 2px 8px rgba(0, 0, 0, 0.32));
}

.btn-grad, a.btn-grad {
  @apply py-3.5 px-4 font-bold tracking-wide rounded-sm leading-[120%] uppercase text-sm whitespace-nowrap shadow-lg text-black transition-colors duration-200;
}

.btn-slider {
  @apply p-0.5 rounded-sm shadow-lg;
  filter: drop-shadow(0 2px 6px rgba(0, 0, 0, 0.12));
}
.btn-slider:hover:not([disabled]) {
  @apply scale-95;
  filter: drop-shadow(0 2px 8px rgba(0, 0, 0, 0.32));
}

.btn-admin, a.btn-admin {
  @apply px-3 py-2 rounded text-xs font-bold;
}

li.menu-item:hover {
  @apply bg-[#DEDEDE];
}

/* ADMIN */
body.theme-Admin {
  @apply text-neutral-800;
  font-family: 'Inter', 'Arial', 'sans-serif';
}

.theme-Admin ul:not(.opt-out) {
  @apply font-bold list-inside list-disc;
}

.theme-Admin ol:not(.opt-out) {
  @apply font-bold list-inside list-decimal;
}

.theme-Admin a:not(.opt-out) {
  @apply underline font-bold text-blue-500;
}

.theme-Admin h1:not(.opt-out) {
  @apply text-3xl font-semibold tracking-wider capitalize;
  line-height: 1.2;
}

.theme-Admin h2:not(.opt-out) {
  @apply text-2xl font-semibold tracking-wide;
  line-height: 1.2;
}

.theme-Admin h3:not(.opt-out) {
  @apply text-xl font-semibold tracking-wide;
  line-height: 1.2;
}

.theme-Admin h4:not(.opt-out) {
  @apply font-bold tracking-widest uppercase;
  line-height: 1.2;
}

.theme-Admin h5:not(.opt-out) {
  @apply font-bold tracking-widest uppercase;
}

.theme-Admin h6:not(.opt-out) {
  @apply font-bold tracking-widest uppercase;
}

.theme-Admin mat-icon:not(.opt-out).material-icons {
  @apply w-auto h-auto;
  font-size: inherit;
}

/* SITE STYLES */
/* Alaska */
body.theme-Alaska {
  @apply font-alaskaSans;
  background-color: #FBFCFD;
}

.theme-Alaska .font-theme-sans {
  @apply font-alaskaSans;
}

.theme-Alaska .font-theme-serif {
  @apply font-alaskaSerif;
}

.theme-Alaska .font-theme-display {
  @apply font-alaskaDisplay;
}

.theme-Alaska ul:not(.opt-out) {
  @apply pl-0 ml-2 list-inside list-disc text-sm font-bold leading-[150%];
}
.theme-Alaska ul:not(.opt-out) li {
  @apply pl-4;
}

.theme-Alaska ol:not(.opt-out) {
  @apply pl-0 ml-2 list-inside list-decimal text-sm font-bold leading-[150%];
}
.theme-Alaska ol:not(.opt-out) li {
  @apply pl-4;
}

.theme-Alaska .btn-grad, .theme-Alaska a.btn-grad {
  @apply border-2 border-[#70D1F0] bg-[#6CDBFF] text-primary-300;
}

.theme-Alaska .btn-grad.btn-grad-alt:hover, .theme-Alaska a.btn-grad.btn-grad-alt:hover {
  @apply border-[#6AC7E4] bg-[#67D0F2];
}

.theme-Alaska .btn-grad:hover, .theme-Alaska a.btn-grad:hover {
  @apply bg-white border-white;
}

.theme-Alaska .btn-slider {
  @apply bg-white;
}

.theme-Alaska h1:not(.opt-out) {
  @apply font-alaskaSerif text-3xl font-semibold leading-[120%] text-secondary-200 tracking-widest uppercase
}

.theme-Alaska h2:not(.opt-out) {
  @apply font-alaskaSerif text-2xl font-semibold leading-[120%] text-secondary-200 tracking-wide;
}

.theme-Alaska h3:not(.opt-out) {
  @apply font-alaskaSerif text-lg font-semibold leading-[120%] text-secondary-200 tracking-wide;
}

.theme-Alaska h4:not(.opt-out) {
  @apply font-alaskaSerif text-lg font-bold leading-[120%] text-black tracking-widest uppercase;
}

.theme-Alaska h5:not(.opt-out) {
  @apply font-alaskaSerif text-base font-semibold leading-[120%] text-secondary-200 tracking-wide;
}

.theme-Alaska h6:not(.opt-out) {
  @apply font-alaskaSerif text-sm font-bold leading-[120%] text-black tracking-widest uppercase;
}

/* Azamara */
body.theme-Azamara {
  @apply font-azamaraSans;
  background-color: #FBFCFD;
}

.theme-Azamara .font-theme-sans {
  @apply font-azamaraSans;
}

.theme-Azamara .font-theme-serif {
  @apply font-azamaraSerif;
}

.theme-Azamara .font-theme-display {
  @apply font-azamaraDisplay;
}

.theme-Azamara ul:not(.opt-out) {
  @apply pl-0 ml-2 list-inside list-disc font-bold;
}
.theme-Azamara ul:not(.opt-out) li {
  @apply pl-4;
}

.theme-Azamara ol:not(.opt-out) {
  @apply pl-0 ml-2 list-inside list-decimal font-bold;
}
.theme-Azamara ol:not(.opt-out) li {
  @apply pl-4;
}

.theme-Azamara .btn-grad, .theme-Azamara a.btn-grad {
  @apply border-2 border-[#6666661F] bg-silver-grad-1;
}

.theme-Azamara .btn-grad:hover, .theme-Azamara a.btn-grad:hover {
  @apply bg-silver-grad-2;
}

.theme-Azamara .btn-slider {
  @apply bg-silver-grad-1;
}

.theme-Azamara h1:not(.opt-out) {
  @apply font-azamaraSerif text-3xl font-semibold text-secondary-200 tracking-widest uppercase;
  line-height: 1.2;
}

.theme-Azamara h2:not(.opt-out) {
  @apply font-azamaraSerif text-2xl font-semibold text-secondary-200 tracking-wide;
  line-height: 1.2;
}

.theme-Azamara h3:not(.opt-out) {
  @apply font-azamaraSerif text-lg font-semibold text-secondary-200 tracking-wide;
  line-height: 1.2;
}

.theme-Azamara h4:not(.opt-out) {
  @apply font-azamaraSerif text-lg font-bold tracking-widest uppercase;
  line-height: 1.2;
}

.theme-Azamara h5:not(.opt-out) {
  @apply font-azamaraSerif font-semibold text-secondary-200 tracking-wide;
  line-height: 1.2;
}

.theme-Azamara h6:not(.opt-out) {
  @apply font-azamaraSerif font-bold tracking-widest uppercase;
  line-height: 1.2;
}

/* CruiseInsider */
body.theme-CI {
  @apply font-CISans;
  background-color: #FBFCFD;
}

.theme-CI .font-theme-sans {
  @apply font-CISans;
}

.theme-CI .font-theme-serif {
  @apply font-CISerif;
}

.theme-CI .font-theme-display {
  @apply font-CIDisplay;
}

.theme-CI ul:not(.opt-out) {
  @apply pl-0 ml-2 list-inside list-disc font-bold;
}
.theme-CI ul:not(.opt-out) li {
  @apply pl-4;
}

.theme-CI ol:not(.opt-out) {
  @apply pl-0 ml-2 list-inside list-decimal font-bold;
}
.theme-CI ol:not(.opt-out) li {
  @apply pl-4;
}

.theme-CI .btn-grad, .theme-CI a.btn-grad {
  @apply border-2 border-[#6666661F] bg-silver-grad-1;
}

.theme-CI .btn-grad:hover, .theme-CI a.btn-grad:hover {
  @apply bg-silver-grad-2;
}

.theme-CI .btn-slider {
  @apply bg-silver-grad-1;
}

.theme-CI h1:not(.opt-out) {
  @apply font-CISans text-3xl font-semibold text-secondary-200 tracking-widest uppercase;
  line-height: 1.2;
}

.theme-CI h2:not(.opt-out) {
  @apply font-CISans text-2xl font-semibold text-secondary-200 tracking-wide;
  line-height: 1.2;
}

.theme-CI h3:not(.opt-out) {
  @apply font-CISans text-lg font-semibold text-secondary-200 tracking-wide;
  line-height: 1.2;
}

.theme-CI h4:not(.opt-out) {
  @apply font-CISans text-lg font-bold tracking-widest uppercase;
  line-height: 1.2;
}

.theme-CI h5:not(.opt-out) {
  @apply font-CISans font-semibold text-secondary-200 tracking-wide;
  line-height: 1.2;
}

.theme-CI h6:not(.opt-out) {
  @apply font-CISans font-bold tracking-widest uppercase;
  line-height: 1.2;
}

/* Oceania */
body.theme-Oceania {
  @apply font-oceaniaSans;
  background-color: #FBF9F4;
}

.theme-Oceania .font-theme-sans {
  @apply font-oceaniaSans;
}

.theme-Oceania .font-theme-serif {
  @apply font-oceaniaSerif;
}

.theme-Oceania .font-theme-display {
  @apply font-oceaniaDisplay;
}

.theme-Oceania ul:not(.opt-out) {
  @apply pl-0 ml-2 list-inside list-disc font-bold;
}
.theme-Oceania ul:not(.opt-out) li {
  @apply pl-4;
}

.theme-Oceania ol:not(.opt-out) {
  @apply pl-0 ml-2 list-inside list-decimal font-bold;
}
.theme-Oceania ol:not(.opt-out) li {
  @apply pl-4;
}

.theme-Oceania .btn-grad, .theme-Oceania a.btn-grad {
  @apply border-2 border-[#FFEFCE] bg-gold-grad-1;
}

.theme-Oceania .btn-grad:hover, .theme-Oceania a.btn-grad:hover {
  @apply bg-gold-grad-2;
}

.theme-Oceania .btn-slider {
  @apply bg-gold-grad-1;
}

.theme-Oceania h1:not(.opt-out) {
  @apply font-oceaniaSerif text-3xl font-semibold text-secondary-200 tracking-wider capitalize;
  line-height: 1.2;
}

.theme-Oceania h2:not(.opt-out) {
  @apply font-oceaniaSerif text-2xl font-semibold text-secondary-200 tracking-wide;
  line-height: 1.2;
}

.theme-Oceania h3:not(.opt-out) {
  @apply font-oceaniaSerif text-lg font-semibold text-secondary-200 tracking-wide;
  line-height: 1.2;
}

.theme-Oceania h4:not(.opt-out) {
  @apply font-oceaniaSerif text-lg font-bold tracking-widest uppercase;
  line-height: 1.2;
}

.theme-Oceania h5:not(.opt-out) {
  @apply font-extrabold text-true-gray-600 tracking-widest uppercase;
}

.theme-Oceania h6:not(.opt-out) {
  @apply font-bold text-true-gray-600 tracking-widest uppercase;
}

/* regent */
body.theme-Regent {
  @apply font-regentSans;
  background-color: #FBFCFD;
}

.theme-Regent .font-theme-sans {
  @apply font-regentSans;
}

.theme-Regent .font-theme-serif {
  @apply font-regentSerif;
}

.theme-Regent .font-theme-display {
  @apply font-regentDisplay;
}

.theme-Regent ul:not(.opt-out) {
  @apply pl-0 ml-2 list-inside list-disc font-bold;
}
.theme-Regent ul:not(.opt-out) li {
  @apply pl-4;
}

.theme-Regent ol:not(.opt-out) {
  @apply pl-0 ml-2 list-inside list-decimal font-bold;
}
.theme-Regent ol:not(.opt-out) li {
  @apply pl-4;
}

.theme-Regent .btn-grad, .theme-Regent a.btn-grad {
  @apply border-2 border-[#6666661F] bg-silver-grad-1;
}

.theme-Regent .btn-grad:hover, .theme-Regent a.btn-grad:hover {
  @apply bg-silver-grad-2;
}

.theme-Regent .btn-slider {
  @apply bg-silver-grad-1;
}

.theme-Regent h1:not(.opt-out) {
  @apply font-regentSerif text-3xl font-semibold text-secondary-200 tracking-widest uppercase;
  line-height: 1.2;
}

.theme-Regent h2:not(.opt-out) {
  @apply font-regentSerif text-2xl font-semibold text-secondary-200 tracking-wide;
  line-height: 1.2;
}

.theme-Regent h3:not(.opt-out) {
  @apply font-regentSerif text-lg font-semibold text-secondary-200 tracking-wide;
  line-height: 1.2;
}

.theme-Regent h4:not(.opt-out) {
  @apply font-regentSerif text-lg font-bold tracking-widest uppercase;
  line-height: 1.2;
}

.theme-Regent h5:not(.opt-out) {
  @apply font-regentSerif font-semibold text-secondary-200 tracking-wide;
  line-height: 1.2;
}

.theme-Regent h6:not(.opt-out) {
  @apply font-regentSerif font-bold tracking-widest uppercase;
  line-height: 1.2;
}

/* silversea */
body.theme-Silversea {
  @apply font-silverseaSans;
  background-color: #FBFCFD;
}

.theme-Silversea .font-theme-sans {
  @apply font-silverseaSans;
}

.theme-Silversea .font-theme-serif {
  @apply font-silverseaSerif;
}

.theme-Silversea .font-theme-display {
  @apply font-silverseaDisplay;
}

.theme-Silversea ul:not(.opt-out) {
  @apply pl-0 ml-2 list-inside list-disc font-bold;
}
.theme-Silversea ul:not(.opt-out) li {
  @apply pl-4;
}

.theme-Silversea ol:not(.opt-out) {
  @apply pl-0 ml-2 list-inside list-decimal font-bold;
}
.theme-Silversea ol:not(.opt-out) li {
  @apply pl-4;
}

.theme-Silversea .btn-grad, .theme-Silversea a.btn-grad {
  @apply border-2 border-[#6666661F] bg-silver-grad-1;
}

.theme-Silversea .btn-grad:hover, .theme-Silversea a.btn-grad:hover {
  @apply bg-silver-grad-2;
}

.theme-Silversea .btn-slider {
  @apply bg-silver-grad-1;
}

.theme-Silversea h1:not(.opt-out) {
  @apply font-silverseaSerif text-3xl font-semibold text-secondary-200 tracking-widest uppercase;
  line-height: 1.2;
}

.theme-Silversea h2:not(.opt-out) {
  @apply font-silverseaSerif text-2xl font-semibold text-secondary-200 tracking-wide;
  line-height: 1.2;
}

.theme-Silversea h3:not(.opt-out) {
  @apply font-silverseaSerif text-lg font-semibold text-secondary-200 tracking-wide;
  line-height: 1.2;
}

.theme-Silversea h4:not(.opt-out) {
  @apply font-silverseaSerif text-lg font-bold tracking-widest uppercase;
  line-height: 1.2;
}

.theme-Silversea h5:not(.opt-out) {
  @apply font-silverseaSerif font-semibold text-secondary-200 tracking-wide;
  line-height: 1.2;
}

.theme-Silversea h6:not(.opt-out) {
  @apply font-silverseaSerif font-bold tracking-widest uppercase;
  line-height: 1.2;
}

/* Windstar */
body.theme-Windstar {
  @apply font-windstarSans;
  background-color: #FBFCFD;
}

.theme-Windstar .font-theme-sans {
  @apply font-windstarSans;
}

.theme-Windstar .font-theme-serif {
  @apply font-windstarSerif;
}

.theme-Windstar .font-theme-display {
  @apply font-windstarDisplay;
}

.theme-Windstar ul:not(.opt-out) {
  @apply pl-0 ml-2 list-inside list-disc font-bold;
}
.theme-Windstar ul:not(.opt-out) li {
  @apply pl-4;
}

.theme-Windstar ol:not(.opt-out) {
  @apply pl-0 ml-2 list-inside list-decimal font-bold;
}
.theme-Windstar ol:not(.opt-out) li {
  @apply pl-4;
}

.theme-Windstar .btn-grad, .theme-Windstar a.btn-grad {
  @apply border-2 border-[#6666661F] bg-silver-grad-1;
}

.theme-Windstar .btn-grad:hover, .theme-Windstar a.btn-grad:hover {
  @apply bg-silver-grad-2;
}

.theme-Windstar .btn-slider {
  @apply bg-silver-grad-1;
}

.theme-Windstar h1:not(.opt-out) {
  @apply font-windstarSerif text-3xl font-semibold text-secondary-200 tracking-wider capitalize;
  line-height: 1.2;
}

.theme-Windstar h2:not(.opt-out) {
  @apply font-windstarSerif text-2xl font-semibold text-secondary-200 tracking-wide;
  line-height: 1.2;
}

.theme-Windstar h3:not(.opt-out) {
  @apply font-windstarSerif text-lg font-semibold text-secondary-200 tracking-wide;
  line-height: 1.2;
}

.theme-Windstar h4:not(.opt-out) {
  @apply font-windstarSerif text-lg font-bold tracking-widest uppercase;
  line-height: 1.2;
}

.theme-Windstar h5:not(.opt-out) {
  @apply font-extrabold text-true-gray-600 tracking-widest uppercase;
}

.theme-Windstar h6:not(.opt-out) {
  @apply font-bold text-true-gray-600 tracking-widest uppercase;
}
