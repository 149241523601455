@tailwind components;

/* HUBSPOT FORMS */
.ql-editor .hs-form {
  @apply p-4 bg-primary-100;
}

.hs-form .hs-form-field {
  @apply mb-3;
}

.hs-form .hs-form-field>label {
  @apply block mb-0.5 text-white/75 text-xs;
}

.hs-form .hs-input {
  @apply w-full min-w-0 h-10 p-2 rounded-sm bg-white;
}

.hs-form .hs-input.hs-fieldtype-textarea {
  @apply h-16;
}

.hs-form .hs-fieldtype-booleancheckbox {
  @apply flex justify-center;
}

.hs-form .hs-fieldtype-booleancheckbox ul.inputs-list {
  @apply m-0 list-none font-normal;
}

.hs-form .inputs-list .hs-form-booleancheckbox {
  @apply mt-4 mb-6;
}

.hs-form .inputs-list .hs-form-booleancheckbox .hs-form-booleancheckbox-display {
  display: grid;
  grid-template-columns: 1.5rem auto;
  gap: 0.5em;
  font-size: 1rem;
}

.hs-form .inputs-list .hs-form-booleancheckbox .hs-form-booleancheckbox-display span {
  @apply text-white;
}

.hs-form .inputs-list .hs-form-booleancheckbox .hs-form-booleancheckbox-display .hs-input {
  @apply relative w-4 h-4 m-0 appearance-none translate-y-0.5 bg-primary-100 border-2 border-tertiary-200 rounded-sm;
}

.hs-form .inputs-list .hs-form-booleancheckbox .hs-form-booleancheckbox-display .hs-input::before {
  @apply absolute inset-0.5 scale-0 bg-tertiary-200 origin-bottom-left;
  content: "";
  width: 90%;
  height: 90%;
  transition: 120ms transform ease-in-out;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
}

.hs-form .inputs-list .hs-form-booleancheckbox .hs-form-booleancheckbox-display .hs-input:checked::before {
  transform: scale(1);
}

.hs-form input[type="submit"] {
  @apply block mx-auto border-2 rounded-sm px-4 py-2 text-sm font-bold text-primary-300 cursor-pointer uppercase;
}

.theme-Alaska input[type="submit"] {
  @apply py-3 px-5 text-sm font-bold leading-[120%] text-primary-300 tracking-wide border-2 rounded-sm uppercase whitespace-nowrap;
  background-color: #6CDBFF;
  border-color: #70D1F0;
}

.theme-Alaska input[type="submit"]:hover {
  @apply bg-white border-white;
}

.theme-Oceania input[type="submit"] {
  @apply bg-gold-grad-1;
}
.theme-Oceania input[type="submit"]:hover {
  @apply bg-gold-grad-2;
}

.theme-Azamara input[type="submit"],
.theme-CI input[type="submit"],
.theme-Regent input[type="submit"],
.theme-Silversea input[type="submit"],
.theme-Windstar input[type="submit"] {
  @apply bg-silver-grad-1;
}
.theme-Azamara input[type="submit"]:hover
.theme-CI input[type="submit"]:hover
.theme-Regent input[type="submit"]:hover,
.theme-Silversea input[type="submit"]:hover,
.theme-Windstar input[type="submit"]:hover {
  @apply bg-silver-grad-2;
}

.submitted-message {
  @apply text-white text-sm;
}

/* hubspot error msgs */
.hs-form ul.hs-error-msgs,
.hs-form .hs-error-msg {
  @apply list-none text-[#E87F7F] text-xs;
}

.hs-form ul.hs-error-msgs li {
  @apply p-0;
}

.hs-form .hs_error_rollup {
  @apply mx-0 mb-2 text-center;
}

.hs-form .hs-input.invalid {
  border: 3px solid #E87F7F;
}

@media (min-width: 640px) {
  .hs-form .form-columns-2 {
    @apply flex justify-between max-w-none;
  }

  .hs-form .form-columns-2 .hs-form-field {
    @apply flex-1;
  }

  .hs-form .form-columns-2 .hs-form-field:first-child {
    @apply mr-4;
  }
}

/* Email Signup Modal */
#hsEmail input[type=email] {
  @apply text-center;
}

#hsEmail input[type=submit] {
  @apply my-4;
}

.theme-Windstar #hsEmail input[type=submit] {
  @apply w-44 py-3 px-4 text-sm font-bold leading-[120%] text-white tracking-wide bg-none bg-tertiary-200 border-2 rounded-sm border-tertiary-200 uppercase;
}

#hsEmail .hs-form .hs_error_rollup {
  @apply hidden;
}

#hsEmail .hs-form label, #hsFooterEmail .hs-form label {
  @apply sr-only;
}

/* footer form */
#hsFooterEmail .hs-form {
  @apply flex;
}

#hsFooterEmail .hs-form .hs-form-field {
  @apply mb-0;
}

#hsFooterEmail .hs_error_rollup {
  @apply hidden;
}

#hsFooterEmail .hs-submit .actions {
  @apply h-full;
}

#hsFooterEmail input[type="submit"] {
  @apply w-auto h-10 bg-none bg-tertiary-100 font-bold text-white px-3 py-2 text-sm cursor-pointer rounded-r rounded-l-none border-tertiary-200;
}

#hsFooterEmail input[type="submit"]:hover, #hsContact input[type="submit"]:hover {
  @apply bg-tertiary-300 border-tertiary-100 text-sm;
  filter: drop-shadow(0 2px 8px rgba(0, 0, 0, 0.32));
  line-height: unset;
}

#hsFooterEmail input[id^="email"] {
  @apply px-3 py-2 text-sm w-32 rounded-l rounded-r-none;
}

#hsFooterEmail .hs-email .hs-error-msgs {
  @apply w-32;
}

/* quote request form */
#requestQuote .submitted-message {
  @apply text-white;
}

#requestQuote .hs_error_rollup {
  @apply absolute bottom-10;
}

#requestQuote {
  min-height: 144px;
}

#requestQuote .hs-submit {
  @apply absolute bottom-0 right-1/2 translate-x-1/2;
}

/* contact us form */
#hsContact .hs-form .hs-form-field>label {
  @apply text-black;
}

#hsContact .hs-form .hs-input {
  @apply border-2 border-primary-100 rounded-sm;
}

#hsContact .hs-form .hs-input.invalid {
  border: 3px solid #E87F7F;
}

#hsContact input[type="submit"] {
  @apply bg-none text-white bg-tertiary-100 border-tertiary-200;
}

#hsContact .submitted-message {
  @apply text-black;
}

/* ANGULAR FORMS*/
/* these are mixed in with hubspot forms for custom functionality; keep in components layer so we can overwrite in templates */
@layer components {
  .form-errors {
    @apply text-[#E87F7F] text-xs;
  }

  .form-row {
    @apply sm:flex sm:justify-between sm:space-x-4;
  }

  .form-fieldset {
    @apply sm:flex-1 mb-3;
  }

  .form-field {
    @apply w-full min-w-0 h-10 p-2 rounded-sm;
  }

  .form-field.ng-invalid.ng-touched,
  .form-field.ng-dirty.ng-invalid,
  .form-field.hs-invalid {
    border: 3px solid #E87F7F;
  }

  .form-label {
    @apply block mb-0.5 text-white/75 text-xs;
  }
}

/* ACS Request A Quote */
#requestQuoteHubspotForm {
  @apply p-6 border-2 rounded bg-primary-100;
}

#requestQuoteHubspotForm fieldset .hs-input {
 @apply w-full shadow-inset-sm rounded;
}

#requestQuoteHubspotForm label {
  @apply font-bold text-lg;
}

#requestQuoteHubspotForm fieldset {
  width: 100%;
  max-width: none;
}

#requestQuoteHubspotForm input {
  max-width: none;
}

#requestQuoteHubspotForm .hs_lastname {
  float: right;
}

#requestQuote .hs-form .hs-fieldtype-booleancheckbox {
  @apply items-center text-white;
}

@media (min-width: 500px) {
  #requestQuoteHubspotForm .hs_firstname, #requestQuoteHubspotForm .hs_lastname {
    width: 45%
  }
}

@media (min-width: 640px) {
  #requestQuoteHubspotForm input[type="submit"] {
    width: 30%;
  }
}

@media (min-width: 1400px) {
  #requestQuoteHubspotForm .hs_firstname, #requestQuoteHubspotForm .hs_lastname {
    width: 48%
  }
}
